.wrapper {
  max-width: 345px;
  width: 100%;
  display: flex;
  border-radius: 8px;
  align-items: center;
  height: 72px;
  border: solid 1.5px #7b7b7b;
  color: #7b7b7b;
  gap: 12px;
  justify-content: center;
  font-size: 24px;
  cursor: pointer;
}
