.wrapper {
  display: flex;
  width: 100%;
  max-width: 377px;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 100px 15px 90px 15px;
  height: 100vh;
}

.header {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.username {
  max-width: 210px;
  width: 100%;
  font-size: 24px;
  letter-spacing: 1px;
}

.userId {
  font-size: 16px;
  opacity: 56%;
}

.UserCards {
  width: 100%;
  margin-bottom: 8px;
}

.refWrapper {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  width: 100%;
  margin-bottom: 6px;
}

.docsButtonWrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 8px;
  margin-top: 8px;
  margin-bottom: 16px;
}

.refDescription {
  font-size: 16px;
  color: #7b7b7b;
}

.financeButtonWrapp {
  max-width: 342px;
  margin-bottom: 6px;
  width: 100%;
}

.setingsWrapper {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #464646;
  border-radius: 50%;
  cursor: pointer;
  overscroll-behavior: contain;
}

.avatar {
  border-radius: 50%;
  width: 48px;
  height: 48px;
}

.sticky {
  position: sticky;
  top: 20px;
  z-index: 1000;
}

.left {
  display: flex;
  gap: 12px;
  align-items: center;
}

/* finance */
.financeWrapper {
  max-width: 342px;
  margin: 64px auto 85px auto;
}

.financeTitle {
  font-size: 56px;
  letter-spacing: 0.02em;
  margin-top: 8px;
  margin-bottom: 24px;
}

.historyWrap {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.workerWrapper {
  height: 80vh;
  width: 100%;
  overflow: scroll;
}
