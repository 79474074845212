.wrapper {
  max-width: 100vw;
  width: 100%;
  background: linear-gradient(to bottom, #9ee449 0%, #232323 65%);
  padding: 8px 24px 48px 24px;
  position: fixed;
  bottom: 64px;
  left: 0;
  border-radius: 35px 35px 0 0;
  transition: 2s;
}

.green {
  background: linear-gradient(to bottom, #9ee449 0%, #232323 65%);
}

.blue {
  background: linear-gradient(180deg, #49a9e4 0%, #232323 63%);
}

.red {
  background: linear-gradient(180deg, #fb4040 0%, #232323 100%);
}

.purpleBig {
  background: linear-gradient(180deg, #b340fb 0%, #232323 100%);
}

.redBig {
  background: linear-gradient(180deg, #fb4040 0%, #232323 100%);
}

.darckGreenBig {
  background: linear-gradient(180deg, #07e37c 0%, #232323 100%);
}

.goldBig {
  background: linear-gradient(180deg, #eedfa5 0%, #232323 100%);
}

.hole {
  background-color: black;
  width: 72px;
  height: 8px;
  margin: 0 auto;
  border-radius: 9px;
  margin-bottom: 32px;
}

.loaderWrap {
  width: 75px;
  transform-origin: center;
  animation: spin 2s linear infinite, blink 1s infinite;
}

.iconWrap {
  margin-bottom: 32px;
}

.titleWrap {
  max-width: 276px;
  margin-bottom: 96px;
}

.title {
  font-size: 64px;
  max-width: 302px;
  width: 100%;
  margin-bottom: 87px;
}

.description {
  max-width: 310px;
  width: 100%;
  font-size: 16px;
  color: #ffffff69;
  margin-bottom: 102px;
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.2;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* table */

.tableHeader {
  font-size: 40px;
  letter-spacing: 0.02em;
  margin-bottom: 24px;
}

.RefListWrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-height: 50vh;
  overflow: scroll;
}

.listElement {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  min-height: 65px;
}

.listTitle {
  font-size: 16px;
  opacity: 64%;
  margin-bottom: 2px;
}

.listAmount {
  font-size: 32px;
  letter-spacing: -0.02em;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.statick {
  position: static;
}

.relative {
  position: relative;
  margin-top: 20%;
}

.close {
  position: absolute;
  font-size: 50px;
  right: 27px;
  top: 20px;
}
