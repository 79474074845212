.wrapper {
  max-width: 167px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  border-radius: 8px;
  border: solid 0px;
  color: #7b7b7b;
  gap: 4px;
  font-size: 24px;
  cursor: pointer;
  padding: 0 12px;
  background-color: #464646;
}

.imageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  filter: grayscale(100%) brightness(70%);
  opacity: 0.7;
}

.text {
  font-family: Handjet;
  font-size: 14px;
  font-weight: 600;
  line-height: 15.65px;
  letter-spacing: 0.01em;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #7b7b7b;
}