.wrapper {
  max-width: 342px;
  width: 100%;
  border-radius: 16px;
  padding: 16px 16px 16px 16px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: -2;
}
.bigBG {
  background-image: none;
}
.blur {
  width: 100%;
  height: 100%;
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  left: 0;
  top: 0;
  z-index: -1;
  border-radius: 16px;
}
.header {
  display: flex;
  justify-content: space-between;
}

.statistic {
  display: flex;
  justify-content: left;
  gap: 36px;
  color: #ffffff;
}
.statisticWrap {
  display: flex;
  flex-direction: column;
}
.statisticBig {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  padding-bottom: 16px;
}
.statisticTitle {
  font-size: 16px;
  opacity: 56%;
  margin-bottom: 3px;
}
.wavyEquals {
  font-size: 32px;
  font-family: "Handjet-400";
}
.statisticAmount {
  font-size: 32px;
}

.right {
  text-align: right;
}

h2 {
  text-align: left;
}

.small {
  gap: 9px;
}

.label {
  font-size: 40px;
  letter-spacing: 2px;
}

.footerWraper {
  display: flex;
  gap: 34px;
  align-items: center;
}
.description {
  color: gray;
  font-size: 13px;
  letter-spacing: 1px;
  margin-top: 5px;
  margin-bottom: 20px;
  line-height: 17px;
}
.customLine {
  border: 1px solid #5a5a5a;
  margin-left: -16px;
  width: 110%;
}

.footer {
  font-size: 15px;
  color: gray;
  letter-spacing: 1px;
}

.labels {
  display: flex;
  gap: 3px;
  align-items: center;
}

.markBlue {
  color: #49a9e4;
}
.markRed {
  color: #fb4040;
}
.markPurpule {
  color: #b340fb;
}
.markGreen {
  color: #49dfe4;
}
.markDackGreen {
  color: #07e37c;
}
.markLightGreen {
  color: #9ee449;
}
.markOrange {
  color: #eda217;
}
.markPink {
  color: #e307a1;
}

.markGold {
  color: #eedfa5;
}

.backgroundLigthPinkBig {
  background-image: url("/public/images/card-pink-big.png");
}
.backgroundLigthOrange {
  background-image: url("/public/images/card-orange.png");
}
.backgroundLigthOrangeBig {
  background-image: url("/public/images/card-orange-big.png");
}
.backgroundLigthGreen {
  background-image: url("/public/images/card-green-light.png");
}
.backgroundLigthGreenBig {
  background-image: url("/public/images/card-green-light-big.png");
}
.backgroundDarckGreen {
  background-image: url("/public/images/card-green-darck.png");
}
.backgroundDarckGreendBig {
  background-image: url("/public/images/card-green-darck-big.png");
}

.backgroundGoldBig {
  background-image: url("/public/images/card-gold-big.png");
}

.backgroundBlue {
  background-image: url("/public/images/blue-bg-pattern.png");
}

.backgroundRed {
  background-image: url("/public/images/red-bg-pattern.png");
}

.backgroundPurple {
  background-image: url("/public/images/purple-bg-pattern.png");
}

.backgroundGreen {
  background-image: url("/public/images/green-bg-pattern.png");
}

.backgroundBlueBig {
  background-image: url("/public/images/card-blue-big.png");
}

.backgroundRedBig {
  background-image: url("/public/images/card-red-big.png");
}

.backgroundPurpleBig {
  background-image: url("/public/images/card-purple-big.png");
}

.backgroundGreenBig {
  background-image: url("/public/images/card-green-big.png");
}
