.wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}
.elementsWrapper {
  display: flex;
  gap: 24px;
  font-size: 16px;
}
.element {
  opacity: 40%;
  transition: 2s;
}

.progressBar {
  background: #565656;
  border-radius: 5px;
  height: 4px;
  width: 100%;
  margin-top: 8px;
  position: relative;
}

.progressLabel {
  height: 4px;
  position: absolute;
  border-radius: 5px;
  background-color: #9ee449;
}
.progressLabelStart {
  width: 55px;
  transition: 1s;
  left: 0;
}
.progressLabelMidle {
  width: 76px;
  transition: 1s;
  left: 77px;
}

.progressLabelEnd {
  width: 116px;
  transition: 1s;
  left: 177px;
}

.active {
  opacity: 1;
  transition: 1s;
}
