.wrapper {
  max-width: 280px;
  min-height: 380px;
  background: linear-gradient(180deg,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(35, 35, 35, 0.1) 100%);
  border-radius: 16px;
  padding: 19px 16px 16px 16px;
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  align-items: center;
  background-size: 100%;
}

.title {
  display: flex;
  justify-content: space-between;
  color: rgba(255, 255, 255, 0.7);
}

.green {
  background-image: url("/public/images/referal-card-bacground-green.png");
}

.purple {
  background-image: url("/public/images/referal-card-bacground-purple.png");
}

.red {
  background-image: url("/public/images/referal-card-bacground-red.png");
}

.gold {
  background-image: url("/public/images/referal-card-bacground-gold.png");
}

.greenImage {
  background-image: url("/public/images/referal-card-image-green.png");
  width: 244px;
  height: 163px;
  background-repeat: no-repeat;
  background-size: 100%;
  margin-bottom: 20px;
}

.purpleImage {
  background-image: url("/public/images/referal-card-image-purple.png");
  width: 278px;
  height: 169px;
  background-repeat: no-repeat;
  background-size: 100%;
  margin-top: -20px;
  margin-bottom: 32px;
}

.redImage {
  background-image: url("/public/images/referal-card-image-red.png");
  width: 283px;
  height: 215px;
  margin-top: -19px;
  background-repeat: no-repeat;
  background-size: 100%;
}

.goldImage {
  background-image: url("/public/images/referal-card-image-gold.png");
  width: 280px;
  height: 176px;
  background-repeat: no-repeat;
  background-size: 100%;
  margin-top: -20px;
  margin-bottom: 41px;
}

.titleElemnt {
  display: flex;
  align-items: center;
  gap: 3px;
}

.description {
  font-size: 32px;
  letter-spacing: 0.02em;
  margin-bottom: 8px;
}

.descriptionWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.partners {
  white-space: pre-wrap;
  opacity: 64%;
  text-align: center;
  letter-spacing: 0.02em;
  font-size: 14px;
}

.customLine {
  white-space: pre-wrap;
  width: 113%;
  margin-top: 47px;
  height: 1px;
  background: #d9d9d9;
  opacity: 20%;
  margin-bottom: 16px;
}

.footerWrap {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.footerDescription {
  font-size: 16px;
  opacity: 64%;
}

.footerAmount {
  font-size: 32px;
}