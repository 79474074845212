.wrapper {
  display: flex;
  padding: 12px;
  justify-content: space-between;
  background-color: #3f3f3f;
  align-items: center;
  border-radius: 8px;
  align-items: center;
}
.text {
  font-size: 18px;
}

.grayGradient {
  background: linear-gradient(90deg, #232323 0%, #3a3a3a 100%);
}

.detailedWrap {
  display: flex;
  padding: 16px 12px;
  max-width: 342px;
  width: 100%;
  justify-content: space-between;
  background: #ffffff1a;
  border-radius: 16px;
}

.title {
  margin-bottom: 6px;
  font-size: 14px;
  letter-spacing: 0.01em;
  color: #ffffff;
  opacity: 56%;
}
