.balanceWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-image: url("../../../public/images/card-green-light.png");
  background-repeat: no-repeat;
  width: 342px;
  bottom: 0;
  height: 200px;
  left: 0;
  padding: 8px 16px;
  max-height: 150px;
  border-radius: 16px;
}

.value {
  font-size: 48px;
}

.description {
  font-size: 16px;
  color: #ffffff;
  opacity: 50%;
}

.buttonWrapper {
  border-top: 1px solid #768662;
  display: flex;
  justify-content: space-around;
  width: 342px;
  margin-left: -16px;
  padding-top: 16px;
  padding-bottom: 9px;
  position: relative;
}
.line {
  border-left: 1px solid #696969;
  height: 64px;
  position: absolute;
  width: 1px;
  top: 0;
}
