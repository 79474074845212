.wrapper {
  padding: 100px 24px 0px 24px;
  width: 100%;
  background-repeat: no-repeat;
  max-width: 390px;
  margin: 0 auto 92px auto;
}
.wrapperBgRed {
  background-image: url("/public/images/bg-red.png");
}
.wrapperBgPurpule {
  background-image: url("/public/images/bg-purple.png");
}

.wrapperBgDacrkGreen {
  background-image: url("/public/images/bg-darck-green.png");
}
.wrapperBgGold {
  background-image: url("/public/images/bg-gold.png");
}

.backBtnWrapper {
  margin-bottom: 24px;
  width: 80px;
}
