@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Handjet";
  src: url("../public//fonts/Handjet-SemiBold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "Handjet-500";
  src: url("../public/fonts/Handjet-VariableFont_ELGR\,ELSH\,wght.ttf")
    format("truetype");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Handjet-600";
  src: url("../public/fonts/Handjet-VariableFont_ELGR\,ELSH\,wght.ttf")
    format("truetype");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Handjet-400";
  src: url("../public/fonts/Handjet-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Golos-Bold";
  src: url("../public/fonts/golos/GolosText-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Golos-ExtraBold";
  src: url("../public/fonts/golos/GolosText-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Golos-Medium";
  src: url("../public/fonts/golos/GolosText-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Golos-Regular";
  src: url("../public/fonts/golos/GolosText-Regular.ttf") format("truetype");
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
button,
input {
  @apply border-none outline-none;
}

body {
  font-family: Handjet;
  @apply bg-[#232323] text-white leading-none;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  overscroll-behavior: none;
}

#root {
  overflow-y: auto;
  height: 99.99vh;
  position: fixed;
  width: 100%;
  scrollbar-width: none;
  padding-bottom: 80px;
}

#root::-webkit-scrollbar {
  display: none;
}
