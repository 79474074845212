.wrapper {
  display: flex;
  justify-content: space-between;
  padding: 20px 16px;
  border-radius: 8px;
  max-width: 342px;
  width: 100%;
  border: solid 1.5px;
}

.wrapperBlue {
  border-color: #49a9e4;
  color: #49a9e4;
  background-image: url("/public/images/buy-bg-blue.png");
}
.wrapperPurple {
  border-color: #b340fb;
  color: #b340fb;
  background-image: url("/public/images/buy-bg-purple.png");
}
.wrapperRed {
  color: #fb4040;
  border-color: #fb4040;
  background-image: url("/public/images/buy-bg-red.png");
}
.wrapperGreen {
  color: #07e3d1;
  border-color: #07e3d1;
  background-image: url("/public/images/buy-bg-green.png");
}
.wrapperBgDackGreen {
  color: #07e37c;
  border-color: #07e37c;
  background: linear-gradient(90deg, #232323 0%, #07e37c 100%);
}
.wrapperBgLightGreen {
  color: #9ee449;
  border-color: #9ee449;
  background: linear-gradient(90deg, #232323 0%, #9ee449 100%);
}
.wrapperBgOrange {
  color: #eda217;
  border-color: #eda217;
  background: linear-gradient(90deg, #232323 0%, #eda217 100%);
}
.wrapperBgPink {
  color: #e307a1;
  border-color: #e307a1;
  background: linear-gradient(90deg, #232323 0%, #e307a1 100%);
}
.wrapperBgGold {
  color: #eedfa5;
  border-color: #eedfa5;
  background: linear-gradient(90deg, #232323 0%, #eedfa5 100%);
}

.wrapperDisable {
  color: gray;
  border-color: gray;
  background-image: url("/public/images//buy-bg-dis.png");
}
.description {
  letter-spacing: 1.5px;
}
.coast {
  color: white;
}
