.wrapper {
  display: flex;
  gap: 5px;
  align-items: center;
  color: white;
}

.white {
  color: white;
}

.text {
  font-size: 14px;
}
