.wrapper {
  width: 100%;
  max-width: 342px;
  display: flex;
  flex-direction: column;
  padding: 16px 16px 12px 16px;
  background-repeat: no-repeat;
  border-radius: 16px;
  margin-bottom: 40px;
  background-size: 100% 100%;
  background-position: center;
}

.backgroundLigthPinkBig {
  background-image: url("/public/images/card-pink-big.png");
}

.backgroundLigthOrangeBig {
  background-image: url("/public/images/card-orange-big.png");
}

.backgroundLigthGreenBig {
  background-image: url("/public/images/card-green-light-big.png");
}

.backgroundDarckGreendBig {
  background-image: url("/public/images/card-green-darck-big.png");
}

.backgroundGoldBig {
  background-image: url("/public/images/card-gold-big.png");
}

.backgroundBlueBig {
  background-image: url("/public/images/card-blue-big.png");
}

.backgroundRedBig {
  background-image: url("/public/images/card-red-big.png");
}

.backgroundPurpleBig {
  background-image: url("/public/images/card-purple-big.png");
}

.backgroundGreenBig {
  background-image: url("/public/images/card-green-big.png");
}

.title {
  font-size: 40px;
  margin-bottom: 12px;
  letter-spacing: 0.02em;
}

.description {
  white-space: pre-wrap;
  font-size: 16px;
  letter-spacing: 0.01em;
  opacity: 0.6;
  margin-bottom: 14px;
}

.eranTableRef {
  display: flex;
  gap: 10px;
  align-items: center;
  color: #9ee449;
  margin-bottom: 17px;
}

.earnTableDescription {
  text-decoration: underline;
  letter-spacing: 0.01em;
}

.referalText {
  font-size: 18px;
  letter-spacing: 0.02em;
  margin-bottom: 12px;
}

.customLine {
  border: 1px solid #5a5a5a;
  margin-left: -16px;
  width: 110%;
}

.footer {
  margin-top: 15px;
  display: flex;
  flex-direction: column;

  gap: 16px;
}

.footerRow {
  display: flex;
  justify-content: space-between;
}

.footerTitle {
  font-size: 16px;
  opacity: 64%;
  margin-bottom: 4px;
}

.footerAmount {
  font-size: 32px;
  letter-spacing: 0.02em;
}

.right {
  text-align: right;
}