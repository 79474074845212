.settingsWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 420px;
  margin: 0 auto;
  margin-top: 100px;
  margin-bottom: 90px;
  padding: 15px;
}

.arrowWrap {
  width: 100%;
  margin-bottom: 8px;
}

.settingsTitle {
  width: 100%;
  font-size: 56px;
  margin-bottom: 22px;
}

.settingsAvatar {
  width: 140px;
  height: 140px;
  border-radius: 50%;
}
.settingsIconWrap {
  position: relative;
  max-width: 140px;
  margin-bottom: 24px;
}
.changeIconWrap {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #0000008f;
  width: 140px;
  height: 140px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.label {
  color: #7b7b7b;
  font-size: 18px;
  margin-bottom: 12px;
  text-transform: capitalize;
}
.buttons {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 342px;
}

.profileButton p {
  text-transform: capitalize;
}
