.elementsWrapper {
  display: flex;
  gap: 18px;
  overflow: hidden;
  width: 100%;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin-bottom: 16px;
}

.elementsWrapper::-webkit-scrollbar {
  display: none;
}
.element {
  font-size: 16px;
  opacity: 40%;
  transition: 1s;
}
.active {
  opacity: 100%;
  transition: 1s;
}
