.wrapper {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0%;
  z-index: 100;
}

.modal {
  background-image: url("./img/green-bg.png");
  max-width: 342px;
  width: 100%;
  padding: 16px;
  position: relative;
  top: 24vh;
  margin: 0 auto;
  border-radius: 16px;
  background-repeat: no-repeat;
}

.cancelIcon {
  position: absolute;
  right: 12px;
}

.title {
  font-size: 40px;
  letter-spacing: 0.02em;
  margin-bottom: 12px;
}
.description {
  letter-spacing: 0.01em;
  line-height: 17px;
  font-size: 16px;
  opacity: 56%;
  margin-bottom: 24px;
}

.label {
  font-size: 18px;
  letter-spacing: 0.02em;
  margin-bottom: 12px;
}
.field {
  margin-bottom: 12px;
}
