.head {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  color: #5a5a5a;
  margin-bottom: 16px;
}

.refUserCards {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
