.wrap {
  max-width: 342px;
  width: 100%;
  background: #ffffff1a;
  border-radius: 16px;
  color: #a8a8a8;
  margin-bottom: 12px;
  position: relative;
}
.main {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 16px;
}
.moneyList {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.description {
  font-size: 16px;
  margin-bottom: 5px;
}

.amount {
  color: #ffffff;
  font-size: 48px;
}

.amountGreen {
  color: #9ee449;
}

.footer {
  padding: 16px;
  border-top: solid 1px #595959;
}

.memeImg {
  position: absolute;
  bottom: 1px;
  right: -33px;
}
