.wrapper {
  background: linear-gradient(90deg, #232323 0%, #3a3a3a 100%);
  border-radius: 8px;
  padding: 8px 16px 8px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.left {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
}
.mail {
  font-size: 18px;
  letter-spacing: 0.01em;
  margin-bottom: 6px;
}
.level {
  font-size: 15px;
  letter-spacing: 0.01em;
  color: #5a5a5a;
}
.icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.usdt {
  font-size: 20px;
}
