.wrapper {
  padding: 100px 24px 0px 24px;
  width: 100%;
  background-repeat: no-repeat;
  max-width: 390px;
  margin: 0 auto 92px auto;
}

.wrapperBgBlue {
  background-image: url("/public/images/bg-blue.png");
}

.wrapperBgRed {
  background-image: url("/public/images/bg-red.png");
}

.wrapperBgPurpule {
  background-image: url("/public/images/bg-purple.png");
}

.wrapperBgGreen {
  background-image: url("/public/images/bg-green.png");
}
.wrapperBgDarckGreen {
  background-image: url("/public/images/bg-darck-green.png");
}
.wrapperBgLightGreen {
  background-image: url("/public/images/bg-light-green.png");
}
.wrapperBgOrange {
  background-image: url("/public/images/bg-orange.png");
}
.wrapperBgPink {
  background-image: url("/public/images/bg-pink.png");
}
.wrapperBgGold {
  background-image: url("/public/images/bg-gold.png");
}

.backBtnWrapper {
  margin-bottom: 29px;
}

.slotsWrapper {
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-top: 51px;
}
